import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import pt from 'vuetify/lib/locale/pt'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

let light = {
    primary:  "#d63e18",
    secondary: "#008A5C",
    error: colors.red.base,
    warning: colors.amber.lighten1,
    info: colors.cyan.base,
    success: colors.green.base
};

let dark = {}

const opts = {
    theme: {
        themes: {
            light,
            dark
        }
    },
    icons: {
        iconfont: 'mdi'
    },
    lang: {
        locales: { pt },
        current: 'pt',
    },
}

export default new Vuetify(opts)