import axios from "axios";

export default class WatsonApi{
    sendMessage(data){
        const token = 'SEU_TOKEN_BEARER_SUPER_SECRETO'; // Substitua pelo seu token
        const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        };
        return axios.post('https://qaluna.qacoders-academy.com.br/sendMessage',data,config)
                .then(response => {
                    return response.data;
                });
    }
}