<template>
  <div id="app">
    <chat-page-vuetify></chat-page-vuetify>
  </div>
</template>

<script>
import ChatPageVuetify from './components/ChatPageVuetify.vue';


export default {
    name: 'App',
    components: { ChatPageVuetify }
}
</script>

<style>
</style>
