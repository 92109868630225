<template>
  <v-app >
      <audio id="sound">
        <source src="https://notificationsounds.com/storage/sounds/file-sounds-1231-out-of-nowhere.ogg" type="audio/ogg">
      </audio>
      <v-card class="chatbot chatbot-container" v-show="chat" :class="{mobile:isMobile()}">
        <div>
          <v-toolbar color="primary" dark>
            <v-avatar size="50" color="white" class="mx-2">
              <v-icon color="red">mdi-robot</v-icon>
            </v-avatar>
            <v-toolbar-title>ChatBot</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="closeChat"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>

            <v-card-text ref="messagesContainer" class="chatbot-messages" :class="{mobile:isMobile()}">
              <v-slide-y-reverse-transition
                class="py-0"
                group
                tag="v-list"
              >
              <div v-for="(message, index) in messages" :key="index" :class="{'text-right':message.sender  == 'user'}" transition="fab-transition">
                  <div class="message grey lighten-2 text-left" v-if="message.type === 'text'" v-html="message.text">
                  </div>
                  <div class="message grey lighten-2 text-left" v-else-if="message.type === 'image'">
                    <v-img :src="message.src" alt="Imagem"></v-img>
                  </div>
                  <div  v-else-if="message.type === 'menu'">
                    <v-btn color="primary" 
                      rounded 
                      class="mx-1 mt-1 text-transform-none" 
                      v-for="(option, optionIndex) in message.options" :key="optionIndex" 
                      @click="selectOption(option.value)">
                      {{ option.text }}
                    </v-btn>
                  </div>
                  <div  v-else-if="message.type === 'button'">
                    <v-btn color="primary" 
                      rounded 
                      class="mx-1 mt-1 text-transform-none" 
                      v-for="(option, optionIndex) in message.options" :key="optionIndex" 
                      target="_blank"
                      :href="option.value">
                      {{ option.text }} <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </div>
              </div>
              </v-slide-y-reverse-transition>
            </v-card-text>
  
          <v-card>
            <v-card-actions>
              <v-text-field v-model="msg" @keyup.enter="sendMessage" label="Digite uma mensagem..."></v-text-field>
              <v-btn color="primary" @click="sendMessage" fab><v-icon>mdi-send</v-icon></v-btn>
            </v-card-actions>
          </v-card>

        
      </v-card>

      <v-btn v-show="!chat" class="chatbot-container" color="primary" fab @click="openChat"><v-icon>mdi-chat</v-icon></v-btn>
  </v-app>
</template>

<script>
import WatsonApi from '../service/WatsonApi';
export default {
  data() {
    return {
      msg: "",
      messages: [],
      messagesBot: [],
      servce: new WatsonApi(),
      chat:false,
    };
  },
  mounted(){
  },
  methods: {
     sendMessage() {
      const userText = this.msg.trim();
      if(userText){
        this.messages.push({ text: userText, sender: "user", type:"text" });
        this.scrollToBottom();
      }

      // Aqui você pode adicionar lógica para enviar a mensagem do usuário para o chatbot
      // e receber a resposta do chatbot.
      let data = {}
      if(this.msg) data.message = this.msg;
      if(this.user_id) data.user_id = this.user_id;
      if(this.context) data.context = this.context;
      this.msg = "";

      this.servce.sendMessage(data)
                .then(res => {
                  this.user_id = res.user_id;
                  this.context = res.context;

                  res.output.generic.forEach(item => {

                    if(item.response_type == 'text'){
                      this.messagesBot.push({ text: item.text, sender: "bot", type:"text" });
                    }else if(item.response_type == 'image'){
                      this.messagesBot.push({
                        src: item.source, // Substitua pelo URL da imagem que deseja exibir
                        sender: "bot",
                        type: "image",
                      });
                    }else if(item.response_type == 'option'){
                      this.messagesBot.push({
                        options: item.options.map(option => {
                          return { text: option.label, value: option.value.input.text };
                        }),
                        sender: "bot",
                        type: "menu",
                      });
                    }
                  });

                  if(this.context.send_button){
                    let send_button = this.context.send_button;
                      this.messagesBot.push({
                        options: send_button.buttons.map(option => {
                          return { text: option.label, value: option.url };
                        }),
                        sender: "bot",
                        type: "button",
                      });
                    if(send_button.message){
                      this.messagesBot.push({ text: send_button.message, sender: "bot", type:"text" });
                    }
                    this.context.send_button = null;
                  }

                  if(this.context.quick_reply){
                      this.messagesBot.push({
                        options: this.context.quick_reply.map(option => {
                          return { text: option.text, value: option.data };
                        }),
                        sender: "bot",
                        type: "menu",
                      });
                    this.context.quick_reply = null;
                  }

                  

                  let time = 0;
                  this.messagesBot.forEach(item =>  {
                      setTimeout(()=>{
                        this.messages.push(item);
                        this.playSound();
                        this.scrollToBottom();
                      },time)
                      time += 500
                    });
                    this.messagesBot = [];
                })
    },
    scrollToBottom() {
      setTimeout(()=>{
        // Use a referência para o elemento que contém as mensagens
        const messagesContainer = this.$refs.messagesContainer;
        // Role para o final suavemente
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      },500)
    },
    selectOption(option){
      this.msg = option;
      this.sendMessage()
    },
    openChat(){
      this.chat = true;
      if(!this.user_id) this.sendMessage(); 
    },
    closeChat(){
      this.chat = false;
    },
    playSound(){
      var sound = document.getElementById("sound");
      sound.play();
    },
    isMobile() {
      // Verifique se a largura atual da janela é menor que o breakpoint "sm" (small)
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
.chatbot {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction:column
}

.chatbot.mobile{
  height: 100vh;
  max-height: 100vh;
  /* max-height: 100vh; */
  position: initial;
}

.chatbot-messages.mobile{
  /* max-height: 80vh; */
  /* height: 65vh; */
}

.chatbot-messages {
  /* height: 400px; */
  overflow-y: auto;
  flex: 1 1 auto;
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  max-width: 70%;
  display: inline-block;
}

.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.z-index-10000{
  z-index: 10000;
}

.text-transform-none {
  text-transform: none !important;
}

</style>

